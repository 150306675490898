<template lang="pug">
.notification
  transition(name="slide-left")
    .notification-card(v-if="active" :class="type")
      span.notification-message {{ message }}
      i.fal.fa-times.notification-closeicon(@click="close")
</template>

<script>
import { breakpointMixin } from '@/mixins/breakpointMixin';

export default {
  name: 'Notification',
  mixins: [breakpointMixin],
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: '',
    },
    timeout: {
      type: Number,
      default: 4000,
    },
    type: {
      type: String,
      default: 'success',
    },
  },
  data() {
    return {
      timeoutValue: null,
    };
  },
  watch: {
    active(value) {
      if (value) {
        this.timeoutValue = setTimeout(() => this.close(), this.timeout);
      }
    },
  },
  methods: {
    close() {
      this.$notification({ active: false });
      clearTimeout(this.timeoutValue);
    },
  },
};
</script>

<style lang="scss">
.notification {
  position: fixed;
  top: 90px;
  right: 15px;
  z-index: 1000;
  margin-left: 15px;

  &-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 200px;
    height: min-content;
    padding: 10px 20px;
    border-radius: $radius;
    color: $blackOlive;
    box-shadow: 0 0 10px rgba($black, .15);
    font-size: 14px;
    line-height: 20px;
    background: $white;

    &.success {
      color: $white;
      background: $hunterGreen;
    }

    &.error {
      color: $white;
      background: $red;
    }

    &.warning {
      color: $white;
      background: $fulvous;
    }
  }

  &-closeicon {
    margin-left: 15px;
    padding: 5px;
    cursor: pointer;
  }

  @media screen and (max-width: $breakpoint-md) {
    top: 75px;
  }
}
</style>
